// main JS from application.js
// import "./vendor/bootstrap.bundle.min.js"; // Loads Bootstrap 5.3
// import "./vendor/flatpickr.min.js";

// // import { Choices } from "choices.js/public/assets/scripts/choices.min.js";
// import "./vendor/choices.min.js";

// import { tns } from 'tiny-slider/src/tiny-slider';
// window.tns = tns

// require("./public/functions.js");
// import { Turbo } from "@hotwired/turbo-rails"

// Show specific JS ////////////////

// import 'bootstrap-star-rating'
import './vendor/bootstrap-star-rating/js/star-rating.min.js';

import 'bootstrap/js/src/collapse';
// import 'bootstrap/js/src/modal';

// import GLightbox from 'glightbox/dist/js/glightbox.min.js'
// import GLightbox from './vendor/glightbox.min.js';
import GLightbox from 'glightbox';
window.GLightbox = GLightbox

import dayjs from 'dayjs';
window.$ = $;
// ajax call for disabledDates in datetimepicker calendars
const initReservationDatesPickers = function() {
  // $.fn.datepicker.defaults.format = "yyyy-mm-dd";
  // $.fn.datepicker.defaults.todayHighlight = true;
  // $.fn.datepicker.defaults.autoclose = true;

  const rentalId = $('#booking_price').data('rental-id')
  $.ajax({
    url: '/rentals/get_disabled_dates',
    type: 'GET',
    data: {
      id: rentalId
    },
    success: (data) => {
      // $('.reservation-dates input').each(function() {
      //   $(this).flatpickr({
      //     minDate: 'today',
      //     disable: data.disabledDates});
      // })
      // const fp_show_checkin = flatpickr(".show-checkin", {minDate: 'today', disable: data.disabledDates});
      // const fp_show_checkout = flatpickr(".show-checkout", {minDate: 'today', disable: data.disabledDates});
      // const fp_reservation_arrival_date = flatpickr("#reservation_arrival_date", {minDate: 'today', disable: data.disabledDates});
      // const fp_reservation_departure_date = flatpickr("#reservation_departure_date", {minDate: 'today', disable: data.disabledDates});
      flatpickr('.show-checkin', {
        minDate: 'today',
        disable: data.disabledDates})
      flatpickr('.show-checkout',{
        minDate: 'today',
        disable: data.disabledDates})
      flatpickr('#reservation_arrival_date',{
        minDate: 'today',
        disable: data.disabledDates})
      flatpickr('#reservation_departure_date',{
        minDate: 'today',
        disable: data.disabledDates})  
    },
    dataType: "json"
  })
}

// for booking price if exists
const checkPrice = function() {
  const rentalId = $('#booking_price').data('rental-id')
  const checkin = $('#reservation_arrival_date').val()
  const checkout = $('#reservation_departure_date').val()
  if (checkin.length > 5 && checkout.length > 5) {
    $.ajax({
      url: '/rentals/get_total_price',
      type: 'GET',
      data: {
        id: rentalId,
        checkin: checkin,
        checkout: checkout,
      },
      success: (data) => {
        if (data.conflict) {
          $('#modal_conflict_alert').show()
          $('#modal_conflict_msg').text("Blocked dates with booking!")
          // alert("Blocked dates with booking!")
          $('#show_conflict_alert').show()
          $('#show_conflict_msg').text("Blocked dates with booking!")
          $('#total_rate').hide()
          $('#max_minstay').hide()
          $('#show_max_minstay').hide()
          $('.rsv_button').addClass('disabled')
          $('.rsv_button').prop('disabled',true)
          $('.inq_button').show()
          $('.inq_button').removeClass('disabled')
        }
        else {
          $('#total_rate').show()
          if ($('.rsv_button').length > 0) {
            $('.rsv_button').removeClass('disabled')
            $('.rsv_button').prop('disabled',false)
            $('.inq_button').hide()
          }
          $('#modal_conflict_alert').hide()
          $('#show_conflict_alert').hide()
          $('#max_minstay').show()
          $('#max_minstay').text(data.max_minstay_msg)
          $('#show_max_minstay').show()
          $('#show_max_minstay').text(data.max_minstay_msg)
          $('#booking_price').text(data.total_price)
          if (data.total_price === 0 ) {
            $('#modal_conflict_alert').show()
            $('#modal_conflict_msg').text("Missing rate! Send simple Message to Owner.")
          }
          $('#booking_price_form').val(data.total_price)
        }
      },

      error: () => {
        $('#total_rate').hide()
        $('#max_minstay').hide()
        $('#show_max_minstay').hide()
        $('.rsv_button').addClass('disabled')
        $('.rsv_button').prop('disabled',true)
        $('.inq_button').show()
        $('#modal_conflict_alert').show()
        $('#modal_conflict_msg').text("Missing rate! Send Message to Owner.")
      },
      dataType: "json"
    })
  }
}

document.addEventListener("turbo:load", function() {
  $.fn.ratingThemes['krajee-uni'] = {
    filledStar: '&#x2605;',
    emptyStar: '&#x2606;',
    clearButton: '&#x229d;'
  };

  $('#review_rating').rating({
    min: 0,
    max: 5,
    step: 1,
    size: 'xs',
    theme: 'krajee-uni',
    showCaption: false,
    showClear: false,
    showCaptionAsTitle: false
  })

  // $('#rental #photos').royalSlider({
  //   fullscreen: {
  //     enabled: true,
  //     nativeFS: true,
  //     buttonFS: true
  //   },
  //   thumbs: {
  //     firstMargin: 0
  //   },
  //   controlNavigation: 'thumbnails',
  //   autoScaleSlider: true,
  //   autoScaleSliderWidth: 732,
  //   autoScaleSliderHeight: 566,
  //   loop: false,
  //   numImagesToPreload: 4,
  //   imageScalePadding: 0,
  //   slidesSpacing: 1,
  //   arrowsNavAutohide: true,
  //   arrowsNavHideOnTouch: true,
  //   keyboardNavEnabled: true
  // });

  // $('.show-checkin').on('change', function (e) {
  //   let inq_checkin = $(e.target).val()
  //   $('#reservation_arrival_date').val(inq_checkin)
  // })

  // $('.show-checkout').on('change', function (e) {
  //   let inq_checkout = $(e.target).val()
  //   $('#reservation_departure_date').val(inq_checkout)
  //   checkPrice()
  // })

  $('.show-checkin').on('change', function (e) {
    let sh_checkin = $(e.target).val()
    $('#reservation_arrival_date').val(sh_checkin)
    // fp_reservation_arrival_date.setDate(sh_checkin)
    document.querySelector('#reservation_arrival_date')._flatpickr.setDate(sh_checkin)
    let sh_checkout = $('.show-checkout').val()
    sh_checkin = new Date(sh_checkin)
    if(sh_checkout == '' || (sh_checkout != '' && sh_checkin >= new Date(sh_checkout))) {
      $('.show-checkout').val(dayjs(sh_checkin).add(1, 'days').format('YYYY-MM-DD'))
      document.querySelector('.show-checkout')._flatpickr.setDate(dayjs(sh_checkin).add(1, 'days').format('YYYY-MM-DD'))
    }
  })

  $('.show-checkout').on('change', function (e) {
    let sh_checkout = $(e.target).val()
    $('#reservation_departure_date').val(sh_checkout)
    document.querySelector('#reservation_departure_date')._flatpickr.setDate(sh_checkout)
    let sh_checkin = $('.show-checkin').val()
    if(new Date(sh_checkout) <= new Date(sh_checkin) || sh_checkin == '') {
      $('.show-checkin').val(dayjs(sh_checkout).subtract(1,'days').format('YYYY-MM-DD'))
      document.querySelector('.show-checkin')._flatpickr.setDate(dayjs(sh_checkout).subtract(1, 'days').format('YYYY-MM-DD'))
    }
    checkPrice()
  })

  $('#reservation_arrival_date').on('change', function (e) {
    let inq_checkin = $(e.target).val()
    let inq_checkout = $('#reservation_departure_date').val()
    inq_checkin = new Date(inq_checkin)
    if(inq_checkout == '' || (inq_checkout != '' && inq_checkin >= new Date(inq_checkout))) {
      $('#reservation_departure_date').val(dayjs(inq_checkin).add(1, 'days').format('YYYY-MM-DD'))
      document.querySelector('#reservation_departure_date')._flatpickr.setDate(dayjs(inq_checkin).add(1, 'days').format('YYYY-MM-DD'))
    //   $('#reservation_departure_date').trigger('change')
    }
    checkPrice()
  })

  $('#reservation_departure_date').on('change', function (e) {
    let checkout = $(e.target).val()
    let checkin = $('#reservation_arrival_date').val()
    if(new Date(checkout) <= new Date(checkin) || checkin == '') {
      $('#reservation_arrival_date').val(dayjs(checkout).subtract(1,'days').format('YYYY-MM-DD'))
      $document.querySelector('#reservation_arrival_date')._flatpickr.setDate(dayjs(checkout).subtract(1, 'days').format('YYYY-MM-DD'))
    }
    checkPrice()
  })

  if ($('.reservation-dates').length > 0) {
    initReservationDatesPickers();
    // $('#reservation_arrival_date').on('changeDate', function (e) {
    //   checkPrice();
    //   // $(e.target).datepicker('hide')
    // });
    // $('#reservation_departure_date').on('changeDate', function (e) {
    //   checkPrice();
    // });
  }

  $('#reservation_email').on('keyup', (e) => {
    $('.inq_button').removeClass('disabled');
    $('.inq_button').prop('disabled',false)
  });

  $('#reservation_adults').on('keyup', (e) => {
    if ($('#reservation_email').val().length > 0) {
      $('.inq_button').removeClass('disabled');
      $('.inq_button').prop('disabled',false)
    }
  });

  $('.inq_button').on('click', () => {
    const firstname = $('#reservation_firstname').val();
    const lastname = $('#reservation_lastname').val();
    const email = $('#reservation_email').val();
    const country_code = $('#reservation_country_code').val();
    const phone = $('#reservation_phone').val();
    const checkin = $('#reservation_arrival_date').val();
    const checkout = $('#reservation_departure_date').val();
    const adults = $('#reservation_adults').val();
    const children = $('#reservation_children').val();
    const message = $('#reservation_message').val();
    const price = $('#booking_price_form').val();
    $("#inquiry_firstname").val(firstname);
    $("#inquiry_lastname").val(lastname);
    $("#inquiry_email").val(email);
    $("#inquiry_country_code").val(country_code);
    $("#inquiry_phone").val(phone);
    $("#inquiry_arrival_date").val(checkin);
    $("#inquiry_departure_date").val(checkout);
    $("#inquiry_adults").val(adults);
    $("#inquiry_children").val(children);
    $("#inquiry_message").val(message);
    $("#inquiry_price").val(price);
    // $("#new_inquiry").submit();
    const rentalId = $('#booking_price').data('rental-id')
    gtag('event', 'requested', {
      'event_category' : 'inquiry',
      'event_label' : rentalId
    });
  });

  $('.rsv_button').on('click', () => {
    const rentalId = $('#booking_price').data('rental-id')
    gtag('event', 'reservested', {
      'event_category' : 'reservation',
      'event_label' : rentalId
    });
  });

})
